<template>
<section class="pt-8 pt-md-5 pb-8 pb-md-14">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="mb-6 text-danger" v-for="error in errors.__all__">{{ error }}</p>
                <Spinner v-if="!loaded" />
                <form class="mb-6" @submit.prevent="save" v-else>
                    <div class="form-group">
                        <label class="form-label" for="email">Email address</label>
                        <input type="email" class="form-control" :class="{'is-invalid': errors.email}" id="email"
                               placeholder="Enter your Email" v-model="form.email">
                        <small class="form-text text-danger" v-for="error in errors.email">{{ error }}</small>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="first_name">First name</label>
                        <input type="text" class="form-control" :class="{'is-invalid': errors.first_name}" id="first_name"
                               placeholder="Enter your first name" v-model="form.first_name">
                        <small class="form-text text-danger" v-for="error in errors.first_name">{{ error }}</small>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="last_name">Last name</label>
                        <input type="text" class="form-control" :class="{'is-invalid': errors.last_name}" id="last_name"
                               placeholder="Enter your last name" v-model="form.last_name">
                        <small class="form-text text-danger" v-for="error in errors.last_name">{{ error }}</small>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="email">Billed to</label>
                        <input type="billed_to" class="form-control" :class="{'is-invalid': errors.billed_to}" id="billed_to"
                            placeholder="Enter your company name" v-model="form.billed_to">
                        <small class="form-text text-danger" v-for="error in errors.billed_to">{{ error }}</small>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="email">Address</label>
                        <input type="address" class="form-control" :class="{'is-invalid': errors.address}" id="address"
                            placeholder="Enter your address" v-model="form.address">
                        <small class="form-text text-danger" v-for="error in errors.address">{{ error }}</small>
                    </div>
                    <button class="btn w-100 btn-primary" type="submit" :disabled="disabled">
                        Save
                    </button>
                </form>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { NotifyMixin } from '@/mixins'
import { displayErrors } from "utils/string"
import Spinner from '../Spinner'

export default {
    mixins: [NotifyMixin],
    components: { Spinner },
    data() {
        return {
            errors: {},
            form: {},
            loaded: false,
            disabled: false
        }
    },
    methods: {
        fetch() {
            let fields = ["id", "email", "first_name", "last_name", "billed_to", "address"]
            this.$root.Context.get().then(resp => {
                for (let field of fields) {
                    this.$set(this.form, field, resp.body[field])
                }
            })
        },
        save() {
            this.errors = {}
            this.disabled = true
            this.$root.Context.save(this.form).then(resp => {
                this.errors = resp.body.errors || {}
                if (resp.body.state) {
                    this.successNotify({ message: "Successful" });
                } else {
                    displayErrors(resp, this);
                }
            }).catch(resp => {
                displayErrors(resp, this);
            }).finally(() => {
                this.disabled = false
            })
        }
    },
    created() {
        this.fetch()
        this.loaded = true;
    }
}
</script>
