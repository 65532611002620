var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "section-border border-primary" }, [
      _c("div", { staticClass: "container d-flex flex-column" }, [
        _c(
          "div",
          {
            staticClass:
              "row align-items-center justify-content-center gx-0 min-vh-100",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11",
              },
              [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: {
                    src: "/assets/img/illustrations/illustration-1.png",
                    alt: "...",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11",
              },
              [
                _c("h1", { staticClass: "display-3 fw-bold text-center" }, [
                  _vm._v("404"),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mb-5 text-center text-muted" }, [
                  _vm._v("It looks like this page no longer exists"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-center" }, [
                  _c(
                    "a",
                    { staticClass: "btn btn-primary", attrs: { href: "/" } },
                    [_vm._v("Home")]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }