var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section-border border-primary" }, [
    _c("div", { staticClass: "container d-flex flex-column" }, [
      _c(
        "div",
        {
          staticClass:
            "row align-items-center justify-content-center gx-0 min-vh-100",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.state
            ? _c(
                "div",
                {
                  staticClass:
                    "col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11",
                },
                [
                  _c("h1", { staticClass: "text-center form-title mb-3" }, [
                    _vm._v(
                      'You have successfully unsubscribed from mailing list "' +
                        _vm._s(_vm.$route.params.code) +
                        '"'
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { to: { path: "/dashboard" } },
                        },
                        [_vm._v("Console")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _c(
                "div",
                {
                  staticClass:
                    "col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11",
                },
                [
                  _c("h1", { staticClass: "text-center form-title mb-3" }, [
                    _vm._v("Your link is not valid"),
                  ]),
                  _vm._v(" "),
                  _vm._m(1),
                ]
              ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11",
      },
      [
        _c("img", {
          staticClass: "img-fluid",
          attrs: {
            src: "/assets/img/illustrations/illustration-4.png",
            alt: "...",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("p", [_vm._v("You may have already clicked on it?")]),
      _vm._v(" "),
      _c("a", { staticClass: "btn btn-primary", attrs: { href: "/" } }, [
        _vm._v("Home"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }