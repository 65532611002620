<template>
<section class="pt-8 pt-md-5 pb-8 pb-md-14">
    <div class="container">
        <Spinner v-if="!loaded" />
        <div class="row" v-else>
            <div class="col-12">
                <h1 class="mb-0 fw-bold">Plan</h1>
                <p class="mb-6 text-muted" v-if="current.name">
                    Your current plan <span class="fw-bold text-primary-desat">{{ current.name }}</span><br>
		    Your current rate limit <span class="fw-bold text-primary-desat">{{ ratelim }} requests per day</span><br>
                    Funds are debited hourly according to your chosen plan
                </p>
                <Plans button="Choice" :disabledId="current.id" />
            </div>
        </div>
    </div>
</section>
</template>

<script>
import Plans from "../Plans"
import Spinner from "../Spinner"
export default {
    components: { Plans, Spinner },
    data() {
        return {
            loaded: false,
            current: {}
        }
    },
    methods: {
        fetch() {
            this.$root.Context.get().then(resp => {
                this.current = resp.body.plan;
		this.ratelim = resp.body.rate_limit;
            }).finally(() => {
                this.loaded = true
            })
        }
    },
    created() {
        this.fetch()
    }
}
</script>
