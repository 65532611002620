var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "pt-12 pt-md-14 mt-n11 bg-primary" }),
    _vm._v(" "),
    _c("section", { staticClass: "pt-8 pt-md-11 pb-12" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row align-items-center" }, [
          _c("div", { staticClass: "col-12 col-md" }, [
            _c("h1", { staticClass: "display-4 mb-8" }, [
              _vm._v(_vm._s(_vm.article.title)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", {
            staticClass: "col-12",
            domProps: { innerHTML: _vm._s(_vm.article.html_content) },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }