function toDate (value) {
    if (!value)
        return ""
    let options = {year: 'numeric', month: 'numeric', day: 'numeric'}
    return new Intl.DateTimeFormat("en", options).format(new Date(parseInt(value) * 1000))
}

function toDateTime (value) {
    if (!value)
        return ""
    let options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}
    return new Intl.DateTimeFormat("en", options).format(new Date(parseInt(value) * 1000))
}

function toHour (value) {
    if (!value)
        return ""
    let options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}
    return new Intl.DateTimeFormat("en", options).format(new Date(parseInt(value) * 1000))
}

module.exports = { toDate, toDateTime, toHour };
