<template>
<section class="pt-8 pt-md-5 pb-8 pb-md-14">
    <div class="container">
        <Spinner v-if="!loaded" />
        <div class="row" v-else>
            <div class="col-12">
                <h1 class="mb-0 fw-bold">Tokens</h1>
                <p class="mb-6 text-muted">
                    Create tokens and use to access your API<br>
                    <a :href="`${prefix}/docs`" v-if="$root.context.id">{{ prefix }}/docs</a> (Cloudflare, Swagger)<br>
                    <a :href="`${prefix}/redoc`" v-if="$root.context.id">{{ prefix }}/redoc</a> (Cloudflare, Redoc)<br>
                    <a href="https://api.instagrapi.com/docs" v-if="$root.context.id">https://api.instagrapi.com/docs</a> (without Cloudflare, Swagger)<br>
                    <a href="https://api.instagrapi.com/redoc" v-if="$root.context.id">https://api.instagrapi.com/redoc</a> (without Cloudflare, Redoc)
                </p>
                <div class="table-responsive">
                    <vuetable
                        ref="vuetable"
                        :api-mode="false"
                        :data="tableData"
                        class="ui celled table unstackable table-scroll"
                        wrapper-class="vuetable-wrapper ui basic segment"
                        table-wrapper=".vuetable-wrapper"
                        :fields="fields"
                        @vuetable:pagination-data="onPaginationData"
                        >
                        <template slot="actions" slot-scope="props">
                            <button class="btn btn-primary mb-1 btn-xs" @click="edit(props.rowData)">Edit</button>
                            <button class="btn btn-danger mb-1 btn-xs" @click="remove(props.rowData)">Delete</button>
                        </template>
                    </vuetable>
                    <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
                </div>
                <button class="btn btn-primary mb-1 btn-xs" @click="create" :disabled="disabled">Create a token</button>
                <p v-if="$root.context.id">
                    <br>
                    To use the API you need to pass "Access Key" in headers:<br>
                    <pre><code class="highlight sh">
                        curl -X GET {{ prefix }}/v2/user/by/id?id=123123 \
                          -H 'accept: application/json' \
                          -H 'x-access-key: {{ firstAccessKey }}'
                    </code></pre>
                    or as a GET-parameter:<br>
                    <pre><code class="highlight bash">
                        curl -X GET {{ prefix }}/v2/user/by/id?id=123123&access_key={{ firstAccessKey }} \
                          -H 'accept: application/json'
                    </code></pre>
                </p>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { toDateTime } from "utils/date";
import Vuetable from "vuetable-2/src/components/Vuetable.vue";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination.vue";
import Spinner from "../Spinner"
export default {
    components: {
        Vuetable,
        VuetablePagination,
        Spinner,
    },
    data() {
        return {
            firstAccessKey: "<YOUR ACCESS KEY>",
            disabled: false,
            loaded: false,
            fields: [
                { name: "name", title: "Name" },
                { name: "access_key", title: "Access key" },
                { name: "created_at", title: "Date of creation", callback: toDateTime },
                { name: "__slot:actions", title: "Actions" },
            ],
            tableData: {}
        }
    },
    computed: {
        prefix() {
            return `https://api.${location.hostname}`
        },
    },
    methods: {
        fetch(data) {
            data = data || {}
            this.$root.Token.get(data).then(resp => {
                if (resp.body.data)
                    this.firstAccessKey = resp.body.data[0].access_key
                this.tableData = resp.body;
            }).finally(() => {
                this.loaded = true
            })
        },
        create() {
            this.disabled = true
            this.$root.Token.save().then(resp => {
                this.fetch()
            }).finally(() => {
                this.disabled = false
            })
        },
        edit(data) {
            let name = prompt("Change name", data.name)
            if (name) {
                this.$root.Token.update({id: data.id, name}).then(resp => {
                    this.fetch()
                })
            }
        },
        remove(data) {
            if (!confirm("Are you sure?")) return
            this.$root.Token.remove({id: data.id}).then(resp => {
                this.fetch()
            })
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.fetch({ page });
        }
    },
    created() {
        this.fetch()
    }
}
</script>
