var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "pt-8 pt-md-5 pb-8 pb-md-14" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm.$root.context.token
              ? _c("div", [
                  _vm._v(
                    "\n                    To receive notifications on your telegram go to\n                    "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: `https://t.me/${_vm.$root.context.tg_bot}?start=${_vm.$root.context.token}`,
                      },
                    },
                    [_vm._v("the link")]
                  ),
                ])
              : _c("Spinner"),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }