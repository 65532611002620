var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("section", { staticClass: "pt-12 pt-md-14 mt-n11 bg-primary" }),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "position-relative pt-12 pt-md-14 mt-n11" },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass: "row align-items-center text-center text-md-start",
              },
              [
                _c("div", { staticClass: "col-12 col-md-6" }, [
                  _c("img", {
                    staticClass: "img-fluid mw-130 float-end mb-6 mb-md-0",
                    attrs: {
                      src: "/assets/img/illustrations/illustration-1.png",
                      alt: "...",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-md-6" }, [
                  _c("h1", { staticClass: "display-1 fw-bold" }, [
                    _c("div", [_vm._v("Features")]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "fs-lg text-primary-desat mb-2" }, [
                    _vm._v(
                      "\n                        Get public data of user, posts, stories, highlights, followers and following users\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "fs-lg text-muted mb-2" }, [
                    _vm._v(
                      "\n                        Get public email and phone number, if the user specified them in his business profile\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "fs-lg text-primary-desat mb-2" }, [
                    _vm._v(
                      "\n                        Get public data of post, story, album, reels data and the ability to download content\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "fs-lg text-muted mb-2" }, [
                    _vm._v(
                      "\n                        Get public data of hashtag and location data, as well as a list of posts for them\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "fs-lg text-primary-desat mb-2" }, [
                    _vm._v(
                      "\n                        Get public data of all comments on a post and a list of users who liked it\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "fs-lg text-primary-desat mb-2" }, [
                    _vm._v(
                      "\n                        Download and upload a photo, video, reels, albums and stories\n                    "
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }