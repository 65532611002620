var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      {
        staticClass: "pt-12 pb-10 pt-md-15 pb-md-14",
        staticStyle: {
          "background-image": "url(/assets/img/covers/cover-3.jpg)",
        },
        attrs: { "data-jarallax": "", "data-speed": ".8" },
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col-12 col-md-8 col-lg-6" }, [
              _c(
                "h1",
                {
                  staticClass: "display-3 fw-bold text-white",
                  attrs: { id: "welcomeHeadingSource" },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$root.siteName) +
                      " will help you "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "text-warning",
                    attrs: {
                      "data-typed":
                        '{"strings": ["get users and followers", "get posts and stories", "get likers", "get comments", "get hashtags", "get places", "get clips", "get highlights", "search music", "search hashtags"]}',
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "fs-lg text-white-80 mb-6" }, [
                _vm._v(
                  "\n                        We receive only verified data from social networks\n                    "
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("section", { staticClass: "py-6 py-md-8 bg-warning" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            staticClass: "flickity-viewport-visible",
            attrs: {
              "data-flickity":
                '{"imagesLoaded": true, "initialIndex": 6, "pageDots": false, "prevNextButtons": false, "contain": true}',
            },
          },
          [
            _c("div", { staticClass: "col-12" }, [
              _c("strong", [_vm._v(_vm._s(_vm.$root.siteName))]),
              _vm._v(
                ", the world's largest API hub, is used by over four thousand developers to find, test and connect to some API"
              ),
              _c("br"),
              _vm._v(
                "\n                    — all with a single API key and dashboard!\n                "
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("section", { staticClass: "py-1 py-md-2" }, [_c("Plans")], 1),
    _vm._v(" "),
    _c("section", { staticClass: "pt-8 pb-4 pt-md-10 pb-md-6" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12 col-md-10 col-lg-10 text-center" }, [
            _c("h2", [_vm._v("Examples of using HikerAPI")]),
            _vm._v(" "),
            _c("pre", [
              _c("code", { staticClass: "highlight" }, [
                _vm._v(
                  '\nimport requests\n\nsession = requests.Session()\nsession.headers.update({\n    "x-access-key": "YOUR TOKEN HERE",\n    "accept": "application/json"\n})\n\n# Getting user profile:\nuser = requests.get(\n    "' +
                    _vm._s(_vm.prefix) +
                    '/v1/user/by/id",\n    params={"id": 123123}\n).json()\nprint("User:", user)\n\n# Collect followers:\nfollowers, max_id = [], None\nwhile True:\n    users, max_id = requests.get(\n        "' +
                    _vm._s(_vm.prefix) +
                    '/v1/user/followers/chunk",\n        params={"user_id": 123123, "max_id": max_id}\n    ).json()\n    followers.update(users)\n    if not max_id:\n        break\n\nprint("Length of followers:", len(followers))\n'
                ),
              ]),
              _vm._v("\n"),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "pt-6 pt-md-8" }, [
      _c("div", { staticClass: "container pb-6 pb-md-8 border-bottom" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.$root.context.id,
                expression: "!$root.context.id",
              },
            ],
            staticClass: "row align-items-center",
          },
          [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-md-auto" }, [
              _c(
                "div",
                { staticClass: "text-center text-md-start" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary shadow lift me-1",
                      attrs: { to: { path: "/login" } },
                    },
                    [
                      _vm._v("\n                            Login "),
                      _c("i", {
                        staticClass:
                          "fe fe-arrow-right d-none d-md-inline ms-3",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary-soft lift",
                      attrs: { to: { path: "/registration" } },
                    },
                    [
                      _vm._v(
                        "\n                            Registration\n                        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "py-8 py-md-9" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "row justify-content-between align-items-center" },
          [
            _c(
              "div",
              { staticClass: "col-12 col-md-6 img-skewed img-skewed-end" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "card rounded-lg bg-black mb-6 mb-md-0 img-skewed-item screenshot",
                  },
                  [
                    _c("div", { staticClass: "card-header border-white-10" }, [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("span", {
                          staticClass: "bg-danger rounded-circle",
                          staticStyle: { width: "10px", height: "10px" },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "bg-warning rounded-circle ms-1",
                          staticStyle: { width: "10px", height: "10px" },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "bg-success rounded-circle ms-1",
                          staticStyle: { width: "10px", height: "10px" },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "card-body",
                        staticStyle: { height: "320px" },
                      },
                      [
                        _c("code", {
                          staticClass: "highlight hljs",
                          staticStyle: { "min-height": "183px" },
                          attrs: {
                            "data-typed":
                              '{"backSpeed":6, "strings": ["$&nbsp;pip&nbsp;install&nbsp;hikerapi<br/><span class=\\"text-success\\">Successfully&nbsp;installed&nbsp;hikerapi</span><br/><br/>$&nbsp;python<br/><span class=\\"text-success\\">from</span> <span class=\\"text-primary\\">hikerapi</span> <span class=\\"text-success\\">import</span> Client<br/><span>cl = Client(token=<a href=\\"/tokens\\" class=\\"text-warning\\">ACCESS_KEY</a>)</span><br/><span>user = cl.user_by_username_v2(<span class=\\"text-warning\\">&apos;instagram&apos;</span>)</span>"]}',
                          },
                        }),
                        _c(
                          "span",
                          { staticClass: "typed-cursor typed-cursor--blink" },
                          [_vm._v("|")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-md-5" }, [
              _c("h2", [
                _vm._v(
                  "Universal access to public data is one of our core values"
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "fs-lg text-gray-700" }, [
                _vm._v(
                  "\n                        You can perform direct http requests, or use our "
                ),
                _c(
                  "a",
                  { attrs: { href: "https://pypi.org/project/hikerapi/" } },
                  [_vm._v("client library for python")]
                ),
                _c("br"),
                _c("br"),
                _vm._v("\n                        You can find examples in "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://hiker-doc.readthedocs.io/en/latest/",
                    },
                  },
                  [_vm._v("our official documentation")]
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "pt-8 pb-4 pt-md-4 pb-md-6" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12 col-md-10 col-lg-10 text-center" }, [
            _c("h2", [
              _vm._v("We provide a service capable of "),
              _c("span", { staticClass: "fw-bold text-primary-desat" }, [
                _vm._v("withstanding huge loads"),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n                        We use open APIs to perform distributed queries\n                    "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-md" }, [
      _c("h3", { staticClass: "mb-1" }, [
        _vm._v("Register and start using our service!"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "fs-lg text-muted mb-6 mb-md-0" }, [
        _vm._v(
          "Fund up your account in your personal dashboard, get token and use any plan"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }