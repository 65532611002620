var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { attrs: { "aria-label": "Table Navigation" } }, [
    _c(
      "ul",
      { staticClass: "pagination" },
      [
        _vm.notEnoughPages
          ? _vm._l(_vm.totalPage, function (n) {
              return _c(
                "li",
                {
                  key: n,
                  staticClass: "page-item",
                  class: { active: _vm.isCurrentPage(n) },
                },
                [
                  _c("a", {
                    staticClass: "page-link",
                    attrs: { href: "#" },
                    domProps: { innerHTML: _vm._s(n) },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.loadPage(n)
                      },
                    },
                  }),
                ]
              )
            })
          : _vm._l(_vm.windowSize, function (n) {
              return _c(
                "li",
                {
                  key: n,
                  staticClass: "page-item",
                  class: { active: _vm.isCurrentPage(_vm.windowStart + n - 1) },
                },
                [
                  _c("a", {
                    staticClass: "page-link",
                    attrs: { href: "#" },
                    domProps: { innerHTML: _vm._s(_vm.windowStart + n - 1) },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.loadPage(_vm.windowStart + n - 1)
                      },
                    },
                  }),
                ]
              )
            }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }