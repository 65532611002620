var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "pt-8 pt-md-5 pb-8 pb-md-14" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm._l(_vm.errors.__all__, function (error) {
              return _c("p", { staticClass: "mb-6 text-danger" }, [
                _vm._v(_vm._s(error)),
              ])
            }),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass: "mb-6",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.change.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group mb-5" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "old_password" },
                      },
                      [_vm._v("Old password")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.old_password,
                          expression: "form.old_password",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.errors.old_password },
                      attrs: {
                        type: "password",
                        id: "old_password",
                        placeholder: "Enter your password",
                      },
                      domProps: { value: _vm.form.old_password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.form,
                            "old_password",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.errors.old_password, function (error) {
                      return _c(
                        "small",
                        { staticClass: "form-text text-danger" },
                        [_vm._v(_vm._s(error))]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group mb-5" },
                  [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "password" } },
                      [_vm._v("New password")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.password,
                          expression: "form.password",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.errors.password },
                      attrs: {
                        type: "password",
                        id: "password",
                        placeholder: "Enter new password",
                      },
                      domProps: { value: _vm.form.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "password", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.errors.password, function (error) {
                      return _c(
                        "small",
                        { staticClass: "form-text text-danger" },
                        [_vm._v(_vm._s(error))]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group mb-5" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "password2" },
                      },
                      [_vm._v("Repeat password")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.password2,
                          expression: "form.password2",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.errors.password2 },
                      attrs: {
                        type: "password",
                        id: "password2",
                        placeholder: "Enter new password again",
                      },
                      domProps: { value: _vm.form.password2 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "password2", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.errors.password2, function (error) {
                      return _c(
                        "small",
                        { staticClass: "form-text text-danger" },
                        [_vm._v(_vm._s(error))]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn w-100 btn-primary",
                    attrs: { type: "submit", disabled: _vm.disabled },
                  },
                  [
                    _vm._v(
                      "\n                        Change password\n                    "
                    ),
                  ]
                ),
              ]
            ),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }