<template>
<div>
    <section data-jarallax data-speed=".8" class="pt-12 pb-10 pt-md-15 pb-md-14" style="background-image: url(/assets/img/covers/cover-3.jpg)">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-8 col-lg-6">
                    <h1 class="display-3 fw-bold text-white" id="welcomeHeadingSource">
                        {{ $root.siteName }} will help you <br />
                        <span class="text-warning" data-typed='{"strings": ["get users and followers", "get posts and stories", "get likers", "get comments", "get hashtags", "get places", "get clips", "get highlights", "search music", "search hashtags"]}'></span>
                    </h1>
                    <p class="fs-lg text-white-80 mb-6">
                        We receive only verified data from social networks
                    </p>
                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </section>
    <section class="py-6 py-md-8 bg-warning">
        <div class="container">
            <div class="flickity-viewport-visible"
                 data-flickity='{"imagesLoaded": true, "initialIndex": 6, "pageDots": false, "prevNextButtons": false, "contain": true}'>
                <div class="col-12">
                    <strong>{{ $root.siteName }}</strong>, the world's largest API hub, is used by over four thousand developers to find, test and connect to some API<br>
                    — all with a single API key and dashboard!
                </div>
            </div>
        </div> <!-- / .container -->
    </section>
    <section class="py-8 py-md-9">
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-12 col-md-6 img-skewed img-skewed-end">
                    <!-- Code -->
                    <div class="card rounded-lg bg-black mb-6 mb-md-0 img-skewed-item screenshot">
                        <div class="card-header border-white-10">
                            <!-- Controls -->
                            <div class="d-flex">
                                <span class="bg-danger rounded-circle" style="width: 10px; height: 10px;"></span>
                                <span class="bg-warning rounded-circle ms-1" style="width: 10px; height: 10px;"></span>
                                <span class="bg-success rounded-circle ms-1" style="width: 10px; height: 10px;"></span>
                            </div>
                        </div>
                        <div class="card-body" style="height:320px">
                            <!-- Code -->
                            <code class="highlight hljs" data-typed="{&quot;backSpeed&quot;:6, &quot;strings&quot;: [&quot;$&nbsp;pip&nbsp;install&nbsp;hikerapi<br/><span class=\&quot;text-success\&quot;>Successfully&nbsp;installed&nbsp;hikerapi</span><br/><br/>$&nbsp;python<br/><span class=\&quot;text-success\&quot;>from</span> <span class=\&quot;text-primary\&quot;>hikerapi</span> <span class=\&quot;text-success\&quot;>import</span> Client<br/><span>cl = Client(token=<a href=\&quot;/tokens\&quot; class=\&quot;text-warning\&quot;>ACCESS_KEY</a>)</span><br/><span>user = cl.user_by_username_v2(<span class=\&quot;text-warning\&quot;>&apos;instagram&apos;</span>)</span>&quot;]}" style="min-height: 183px;"></code><span class="typed-cursor typed-cursor--blink">|</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-5">
                    <!-- Heading -->
                    <h2>Universal access to public data is one of our core values</h2>
                    <!-- Text -->
                    <p class="fs-lg text-gray-700">
                        You can perform direct http requests, or use our <a href="https://pypi.org/project/hikerapi/">client library for python</a><br><br>
                        You can find examples in <a href="https://hiker-doc.readthedocs.io/en/latest/">our official documentation</a>
                    </p>
                    <!-- Link -->
                    <!-- <a class="fw-bold text-decoration-none" :href="`${prefix}/docs`"> -->
                    <!--     View our API <i class="fe fe-arrow-right ms-3"></i> -->
                    <!-- </a> -->
                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </section>

    <section class="pt-8 pb-4 pt-md-4 pb-md-6">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10 text-center">
                    <h2>We provide a service capable of <span class="fw-bold text-primary-desat">withstanding huge loads</span></h2>
                    <p>
                        We use open APIs to perform distributed queries
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section class="py-1 py-md-2">
        <Plans />
    </section>
    <section class="pt-8 pb-4 pt-md-10 pb-md-6">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-10 text-center">
                    <h2>Examples of using HikerAPI</h2>
                    <!-- <ul class="nav nav-tabs"> -->
                    <!--     <Tab name="CURL" /> -->
                    <!--     <Tab name="Python" /> -->
                    <!--     <Tab name="Python: hikerapi" /> -->
                    <!--     <Tab name="Javascript" /> -->
                    <!-- </ul> -->
<pre>
<code class="highlight">
import requests

session = requests.Session()
session.headers.update({
&nbsp;&nbsp;&nbsp;&nbsp;"x-access-key": "YOUR TOKEN HERE",
&nbsp;&nbsp;&nbsp;&nbsp;"accept": "application/json"
})

# Getting user profile:
user = requests.get(
&nbsp;&nbsp;&nbsp;&nbsp;"{{ prefix }}/v1/user/by/id",
&nbsp;&nbsp;&nbsp;&nbsp;params={"id": 123123}
).json()
print("User:", user)

# Collect followers:
followers, max_id = [], None
while True:
&nbsp;&nbsp;&nbsp;&nbsp;users, max_id = requests.get(
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"{{ prefix }}/v1/user/followers/chunk",
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;params={"user_id": 123123, "max_id": max_id}
&nbsp;&nbsp;&nbsp;&nbsp;).json()
&nbsp;&nbsp;&nbsp;&nbsp;followers.update(users)
&nbsp;&nbsp;&nbsp;&nbsp;if not max_id:
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;break

print("Length of followers:", len(followers))
</code>
</pre>
                </div>
            </div>
        </div>
    </section>
    <section class="pt-6 pt-md-8">
        <div class="container pb-6 pb-md-8 border-bottom">
            <div class="row align-items-center" v-show="!$root.context.id">
                <div class="col-12 col-md">
                    <h3 class="mb-1">Register and start using our service!</h3>
                    <p class="fs-lg text-muted mb-6 mb-md-0">Fund up your account in your personal dashboard, get token and use any plan</p>
                </div>
                <div class="col-12 col-md-auto">
                    <div class="text-center text-md-start">
                        <router-link :to="{path: '/login'}" class="btn btn-primary shadow lift me-1">
                            Login <i class="fe fe-arrow-right d-none d-md-inline ms-3"></i>
                        </router-link>
                        <router-link :to="{path: '/registration'}" class="btn btn-primary-soft lift">
                            Registration
                        </router-link>
                    </div>
                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </section>
</div>
</template>

<script>
import Plans from './Plans'
export default {
    components: { Plans },
    data() {
        return {}
    },
    computed: {
        prefix() {
            return `https://api.${location.hostname}`
        },
    },
    created() {
        if (this.$route.params && this.$route.params.promocode)
            localStorage.setItem("promocode", this.$route.params.promocode);
        if (location.search.indexOf("paymentId") != -1) {
            this.$router.replace({path: '/billing'})
        }
    }
}
</script>
