<template>
    <li class="nav-item">
        <a class="nav-link"
           :class="{'active': currentTab == name}"
           @click="currentTab = name"
           :href="`#${name}`"
           :help="currentTab"
           >
            <slot></slot>
        </a>
    </li>
</template>

<script>
export default {
    props: {
        name: { required: true, type: String },
        currentTab: { required: true, type: String },
    },
    data() {
        return {
        }
    }
}
</script>
