var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "pt-8 pt-md-11 pb-10 pb-md-15 bg-primary" }, [
      _c("div", { staticClass: "shape shape-blur-3 svg-shim text-white" }, [
        _c(
          "svg",
          {
            attrs: {
              viewBox: "0 0 1738 487",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M0 0h1420.92s713.43 457.505 0 485.868C707.502 514.231 0 0 0 0z",
                fill: "url(#paint0_linear)",
              },
            }),
            _c(
              "defs",
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "paint0_linear",
                      x1: "0",
                      y1: "0",
                      x2: "1049.98",
                      y2: "912.68",
                      gradientUnits: "userSpaceOnUse",
                    },
                  },
                  [
                    _c("stop", {
                      attrs: {
                        "stop-color": "currentColor",
                        "stop-opacity": ".075",
                      },
                    }),
                    _c("stop", {
                      attrs: {
                        offset: "1",
                        "stop-color": "currentColor",
                        "stop-opacity": "0",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12 col-md-10 col-lg-8 text-center" }, [
            _c("br"),
            _vm._v(" "),
            _c("h1", { staticClass: "display-2 text-white" }, [
              _vm._v(_vm._s(_vm.$root.siteName) + " pricing"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "lead text-white-80 mb-2 mb-md-2" }, [
              _vm._v("Choose the plan that's right for you"),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "position-relative" }, [
      _c(
        "div",
        { staticClass: "shape shape-bottom shape-fluid-x svg-shim text-light" },
        [
          _c(
            "svg",
            {
              attrs: {
                viewBox: "0 0 2880 48",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z",
                  fill: "currentColor",
                },
              }),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "mt-n8 mt-md-n14" }, [_c("Plans")], 1),
    _vm._v(" "),
    _c("section", { staticClass: "py-8 py-md-11" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-12 col-md-10 col-lg-8 text-center" }, [
            _c(
              "h2",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.$root.context.id,
                    expression: "!$root.context.id",
                  },
                ],
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "fw-bold text-primary-desat",
                    attrs: { to: { path: "/registration" } },
                  },
                  [_vm._v("Register")]
                ),
                _vm._v(
                  "\n                        and start using our service!\n                    "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticClass: "fs-lg text-muted mb-7 mb-md-9" }, [
              _vm._v(
                "\n                        Fund up your account in your personal dashboard, get token and use any plan\n                    "
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }