<template>
    <section class="py-8 py-md-11 md-n14">
        <div class="container">
              <div class="row" id="portfolio" data-isotope='{"layoutMode": "fitRows"}'>
                <div class="card card-body text-center text-gray-700 shadow-light-lg mb-7 bg-gray-300">
                    You can read every review right here.
                    <a class="d-inline" href="https://www.trustpilot.com/review/hikerapi.com">
                        https://www.trustpilot.com/review/hikerapi.com
                    </a>
                </div>
                <div v-for="review in reviews" class="col-12">
                    <div class="card lift lift-lg shadow-light-lg mb-7">
                        <div class="card-body">
                            <a v-bind:href="review.company_url">
                            <h6 class="text-uppercase mb-1 text-muted">{{ review.company_name }}</h6>
                            </a>

                            <h4 class="mb-1 text-uppercase">{{ review.username }}</h4>

                            <p class="">{{ review.body }}</p>

                            <h6 class="text-muted">{{ new Date(review.reviewed_at).toLocaleDateString() }}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    mounted() {
        this.getReviews();
    },
    methods: {
        getReviews(){
            this.$root.Review.get()
            .then(res => {
                this.reviews.push(...res.body)
            })
        }
    },
    data() {
        return {
            reviews: [],
            showModal: false,
            modalText: ''
        }
    }
}
</script>
