var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("section", { staticClass: "pt-12 pt-md-14 mt-n11 bg-primary" }),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "position-relative pt-12 pt-md-14 mt-n11" },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass:
                  "row align-items-center text-center text-md-start bg-primary-soft",
              },
              [
                _c("div", { staticClass: "col-12 col-md-12" }, [
                  _c("h1", { staticClass: "display-1 fw-bold" }, [
                    _c("div", [_vm._v("Status")]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-2" }, [
                    _c("img", {
                      attrs: {
                        src: "https://api.checklyhq.com/v1/badges/checks/a5c4c09a-e4b7-49a8-a943-a61b93881066?style=for-the-badge&theme=default",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-8" }, [
                    _c("img", {
                      attrs: {
                        src: "https://api.checklyhq.com/v1/badges/checks/a5c4c09a-e4b7-49a8-a943-a61b93881066?style=for-the-badge&theme=dark&responseTime=true",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }