<template>
<div>
    <!-- NAVBAR -->
    <nav class="navbar navbar-expand-lg navbar-dark navbar-togglable fixed-top">
      <div class="container">
        <a class="navbar-brand" href="/">
            {{ $root.siteName }}
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse navbar-text-shadow" id="navbarCollapse">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fe fe-x"></i>
          </button>
          <ul class="navbar-nav ms-auto">
            <li class="nav-item dropdown">
                <a class="nav-link" :href="`${$root.siteProto}://api.${$root.siteDomain}/docs`">API</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="navbarDocumentation" data-bs-toggle="dropdown" href="#" aria-haspopup="true" aria-expanded="false">
                Documentation
              </a>
              <div class="dropdown-menu dropdown-menu-md" aria-labelledby="navbarDocumentation">
                <div class="list-group list-group-flush">
                  <a class="list-group-item" href="https://hiker-doc.readthedocs.io/">
                    <div class="icon icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M8 3v.5A1.5 1.5 0 009.5 5h5A1.5 1.5 0 0016 3.5V3h2a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V5a2 2 0 012-2h2z" fill="#335EEA" opacity=".3"/><path d="M11 2a1 1 0 012 0h1.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H11z" fill="#335EEA"/><rect fill="#335EEA" opacity=".3" x="7" y="10" width="5" height="2" rx="1"/><rect fill="#335EEA" opacity=".3" x="7" y="14" width="9" height="2" rx="1"/></g></svg>
                    </div>
                    <div class="ms-4">
                      <h6 class="fw-bold text-uppercase text-primary mb-0">Documentation</h6>
                      <p class="fs-sm text-gray-700 mb-0">Handbook with tutorials</p>
                    </div>
                  </a>
                  <a class="list-group-item" :href="`${$root.siteProto}://api.${$root.siteDomain}/docs`">
                    <div class="icon icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#335EEA" x="4" y="4" width="7" height="7" rx="1.5"/><path d="M5.5 13h4a1.5 1.5 0 011.5 1.5v4A1.5 1.5 0 019.5 20h-4A1.5 1.5 0 014 18.5v-4A1.5 1.5 0 015.5 13zm9-9h4A1.5 1.5 0 0120 5.5v4a1.5 1.5 0 01-1.5 1.5h-4A1.5 1.5 0 0113 9.5v-4A1.5 1.5 0 0114.5 4zm0 9h4a1.5 1.5 0 011.5 1.5v4a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-4a1.5 1.5 0 011.5-1.5z" fill="#335EEA" opacity=".3"/></g></svg>
                    </div>
                    <div class="ms-4">
                      <h6 class="fw-bold text-uppercase text-primary mb-0">Swagger UI</h6>
                      <p class="fs-sm text-gray-700 mb-0">Making requests online</p>
                    </div>
                  </a>
                  <a class="list-group-item" :href="`${$root.siteProto}://api.${$root.siteDomain}/redoc`">
                    <div class="icon icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#335EEA" x="4" y="4" width="7" height="7" rx="1.5"/><path d="M5.5 13h4a1.5 1.5 0 011.5 1.5v4A1.5 1.5 0 019.5 20h-4A1.5 1.5 0 014 18.5v-4A1.5 1.5 0 015.5 13zm9-9h4A1.5 1.5 0 0120 5.5v4a1.5 1.5 0 01-1.5 1.5h-4A1.5 1.5 0 0113 9.5v-4A1.5 1.5 0 0114.5 4zm0 9h4a1.5 1.5 0 011.5 1.5v4a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-4a1.5 1.5 0 011.5-1.5z" fill="#335EEA" opacity=".3"/></g></svg>
                    </div>
                    <div class="ms-4">
                      <h6 class="fw-bold text-uppercase text-primary mb-0">ReDoc</h6>
                      <p class="fs-sm text-gray-700 mb-0">Documentation with examples</p>
                    </div>
                  </a>
                  <a class="list-group-item" href="/help/changelog">
                    <div class="icon icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M5.857 2h7.88a1.5 1.5 0 01.968.355l4.764 4.029A1.5 1.5 0 0120 7.529v12.554c0 1.79-.02 1.917-1.857 1.917H5.857C4.02 22 4 21.874 4 20.083V3.917C4 2.127 4.02 2 5.857 2z" fill="#335EEA" opacity=".3"/><rect fill="#335EEA" x="6" y="11" width="9" height="2" rx="1"/><rect fill="#335EEA" x="6" y="15" width="5" height="2" rx="1"/></g></svg>
                    </div>
                    <div class="ms-4">
                      <h6 class="fw-bold text-uppercase text-primary mb-0">Changelog</h6>
                      <p class="fs-sm text-gray-700 mb-0">
                        Keep track of changes
                      </p>
                    </div>
                    <!-- <span class="badge rounded-pill bg-primary-soft ms-auto"> -->
                    <!--   2.0.0-beta1 -->
                    <!-- </span> -->
                  </a>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
                <router-link :to="{path: '/status'}" class="nav-link">Status</router-link>
            </li>
            <li class="nav-item dropdown">
                <router-link :to="{path: '/faq'}" class="nav-link">FAQ</router-link>
            </li>
            <li class="nav-item dropdown">
                <router-link :to="{path: '/pricing'}" class="nav-link">Pricing</router-link>
            </li>
            <li class="nav-item dropdown">
              <router-link :to="{path: '/review'}" class="nav-link">Review</router-link>
          </li>
          </ul>
          <router-link :to="{path: '/dashboard'}" class="navbar-btn btn btn-sm btn-primary lift ms-auto" v-if="$root.context.id">Console</router-link>
          <ul class="navbar-nav ms-auto" v-else>
              <li class="nav-item dropdown">
                  <router-link :to="{path: '/registration'}" class="navbar-btn btn btn-sm btn-primary lift ms-auto">Register</router-link>
              </li>
              <li class="nav-item dropdown">
                  <router-link :to="{path: '/login'}" class="nav-link">Login</router-link>
              </li>
          </ul>
        </div>
      </div>
    </nav>
    <main>
        <router-view></router-view>
    </main>
    <div class="position-relative">
        <div class="shape shape-bottom shape-fluid-x svg-shim text-gray-200">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"/></svg>
        </div>
    </div>
    <footer class="footer py-8 py-md-11 bg-gray-200">
        <Footer />
    </footer>
</div>
</template>

<script>
import Footer from './Footer'
export default {
    components: { Footer },
}
</script>
