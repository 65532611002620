var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "position-relative" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "d-flex justify-content-center m-12" }, [
          _c("div", {
            staticClass: "spinner-border text-primary",
            staticStyle: { width: "6rem", height: "6rem" },
            attrs: { role: "status" },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }