var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section-border border-primary" }, [
    _c("div", { staticClass: "container d-flex flex-column" }, [
      _c(
        "div",
        {
          staticClass:
            "row align-items-center justify-content-center gx-0 min-vh-100",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.sent
            ? _c(
                "div",
                {
                  staticClass:
                    "col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11",
                },
                [_vm._m(1)]
              )
            : _c(
                "div",
                {
                  staticClass:
                    "col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11",
                },
                [
                  _c("h1", { staticClass: "mb-0 fw-bold text-center" }, [
                    _vm._v("Password reset"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-6 text-center text-muted" }, [
                    _vm._v("Enter your Email to reset your password"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.errors.__all__, function (error) {
                    return _c(
                      "p",
                      { staticClass: "mb-6 text-center text-danger" },
                      [_vm._v(_vm._s(error))]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      staticClass: "mb-6",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.forgot.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.email,
                                expression: "form.email",
                              },
                            ],
                            staticClass: "form-control",
                            class: { "is-invalid": _vm.errors.email },
                            attrs: {
                              type: "email",
                              id: "email",
                              placeholder: "Enter your Email",
                            },
                            domProps: { value: _vm.form.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(_vm.form, "email", $event.target.value)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.errors.email, function (error) {
                            return _c(
                              "small",
                              { staticClass: "form-text text-danger" },
                              [_vm._v(_vm._s(error))]
                            )
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn w-100 btn-primary",
                          attrs: { type: "submit", disabled: _vm.disabled },
                        },
                        [
                          _vm._v(
                            "\n                        Reset the password\n                    "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "mb-0 fs-sm text-center text-muted" },
                    [
                      _vm._v(
                        "\n                    Remember your password?\n                    "
                      ),
                      _c("router-link", { attrs: { to: { path: "/login" } } }, [
                        _vm._v("Login"),
                      ]),
                      _vm._v(".\n                "),
                    ],
                    1
                  ),
                ],
                2
              ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11",
      },
      [
        _c("img", {
          staticClass: "img-fluid",
          attrs: {
            src: "/assets/img/illustrations/illustration-4.png",
            alt: "...",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "alert alert-success", attrs: { role: "alert" } },
      [
        _c("h4", { staticClass: "alert-heading" }, [_vm._v("Excellent!")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "We sent you an email, follow the link in the email and reset your password"
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }