var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "py-8 py-md-11 md-n14" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        {
          staticClass: "row",
          attrs: {
            id: "portfolio",
            "data-isotope": '{"layoutMode": "fitRows"}',
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.reviews, function (review) {
            return _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "card lift lift-lg shadow-light-lg mb-7" },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("a", { attrs: { href: review.company_url } }, [
                      _c(
                        "h6",
                        { staticClass: "text-uppercase mb-1 text-muted" },
                        [_vm._v(_vm._s(review.company_name))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("h4", { staticClass: "mb-1 text-uppercase" }, [
                      _vm._v(_vm._s(review.username)),
                    ]),
                    _vm._v(" "),
                    _c("p", {}, [_vm._v(_vm._s(review.body))]),
                    _vm._v(" "),
                    _c("h6", { staticClass: "text-muted" }, [
                      _vm._v(
                        _vm._s(
                          new Date(review.reviewed_at).toLocaleDateString()
                        )
                      ),
                    ]),
                  ]),
                ]
              ),
            ])
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "card card-body text-center text-gray-700 shadow-light-lg mb-7 bg-gray-300",
      },
      [
        _vm._v(
          "\n                You can read every review right here.\n                "
        ),
        _c(
          "a",
          {
            staticClass: "d-inline",
            attrs: { href: "https://www.trustpilot.com/review/hikerapi.com" },
          },
          [
            _vm._v(
              "\n                    https://www.trustpilot.com/review/hikerapi.com\n                "
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }