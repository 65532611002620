var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "nav",
      {
        staticClass:
          "navbar navbar-expand-lg navbar-dark navbar-togglable fixed-top",
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("a", { staticClass: "navbar-brand", attrs: { href: "/" } }, [
            _vm._v(
              "\n            " + _vm._s(_vm.$root.siteName) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse navbar-text-shadow",
              attrs: { id: "navbarCollapse" },
            },
            [
              _vm._m(1),
              _vm._v(" "),
              _c("ul", { staticClass: "navbar-nav ms-auto" }, [
                _c("li", { staticClass: "nav-item dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        href: `${_vm.$root.siteProto}://api.${_vm.$root.siteDomain}/docs`,
                      },
                    },
                    [_vm._v("API")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link dropdown-toggle",
                      attrs: {
                        id: "navbarDocumentation",
                        "data-bs-toggle": "dropdown",
                        href: "#",
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                      },
                    },
                    [_vm._v("\n                Documentation\n              ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu dropdown-menu-md",
                      attrs: { "aria-labelledby": "navbarDocumentation" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "list-group list-group-flush" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "list-group-item",
                              attrs: {
                                href: "https://hiker-doc.readthedocs.io/",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "icon icon-sm text-primary" },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            fill: "none",
                                            "fill-rule": "evenodd",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: { d: "M0 0h24v24H0z" },
                                          }),
                                          _c("path", {
                                            attrs: {
                                              d: "M8 3v.5A1.5 1.5 0 009.5 5h5A1.5 1.5 0 0016 3.5V3h2a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V5a2 2 0 012-2h2z",
                                              fill: "#335EEA",
                                              opacity: ".3",
                                            },
                                          }),
                                          _c("path", {
                                            attrs: {
                                              d: "M11 2a1 1 0 012 0h1.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H11z",
                                              fill: "#335EEA",
                                            },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              fill: "#335EEA",
                                              opacity: ".3",
                                              x: "7",
                                              y: "10",
                                              width: "5",
                                              height: "2",
                                              rx: "1",
                                            },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              fill: "#335EEA",
                                              opacity: ".3",
                                              x: "7",
                                              y: "14",
                                              width: "9",
                                              height: "2",
                                              rx: "1",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._m(2),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "list-group-item",
                              attrs: {
                                href: `${_vm.$root.siteProto}://api.${_vm.$root.siteDomain}/docs`,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "icon icon-sm text-primary" },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            fill: "none",
                                            "fill-rule": "evenodd",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: { d: "M0 0h24v24H0z" },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              fill: "#335EEA",
                                              x: "4",
                                              y: "4",
                                              width: "7",
                                              height: "7",
                                              rx: "1.5",
                                            },
                                          }),
                                          _c("path", {
                                            attrs: {
                                              d: "M5.5 13h4a1.5 1.5 0 011.5 1.5v4A1.5 1.5 0 019.5 20h-4A1.5 1.5 0 014 18.5v-4A1.5 1.5 0 015.5 13zm9-9h4A1.5 1.5 0 0120 5.5v4a1.5 1.5 0 01-1.5 1.5h-4A1.5 1.5 0 0113 9.5v-4A1.5 1.5 0 0114.5 4zm0 9h4a1.5 1.5 0 011.5 1.5v4a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-4a1.5 1.5 0 011.5-1.5z",
                                              fill: "#335EEA",
                                              opacity: ".3",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._m(3),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "list-group-item",
                              attrs: {
                                href: `${_vm.$root.siteProto}://api.${_vm.$root.siteDomain}/redoc`,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "icon icon-sm text-primary" },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            fill: "none",
                                            "fill-rule": "evenodd",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: { d: "M0 0h24v24H0z" },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              fill: "#335EEA",
                                              x: "4",
                                              y: "4",
                                              width: "7",
                                              height: "7",
                                              rx: "1.5",
                                            },
                                          }),
                                          _c("path", {
                                            attrs: {
                                              d: "M5.5 13h4a1.5 1.5 0 011.5 1.5v4A1.5 1.5 0 019.5 20h-4A1.5 1.5 0 014 18.5v-4A1.5 1.5 0 015.5 13zm9-9h4A1.5 1.5 0 0120 5.5v4a1.5 1.5 0 01-1.5 1.5h-4A1.5 1.5 0 0113 9.5v-4A1.5 1.5 0 0114.5 4zm0 9h4a1.5 1.5 0 011.5 1.5v4a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-4a1.5 1.5 0 011.5-1.5z",
                                              fill: "#335EEA",
                                              opacity: ".3",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._m(4),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "list-group-item",
                              attrs: { href: "/help/changelog" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "icon icon-sm text-primary" },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            fill: "none",
                                            "fill-rule": "evenodd",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: { d: "M0 0h24v24H0z" },
                                          }),
                                          _c("path", {
                                            attrs: {
                                              d: "M5.857 2h7.88a1.5 1.5 0 01.968.355l4.764 4.029A1.5 1.5 0 0120 7.529v12.554c0 1.79-.02 1.917-1.857 1.917H5.857C4.02 22 4 21.874 4 20.083V3.917C4 2.127 4.02 2 5.857 2z",
                                              fill: "#335EEA",
                                              opacity: ".3",
                                            },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              fill: "#335EEA",
                                              x: "6",
                                              y: "11",
                                              width: "9",
                                              height: "2",
                                              rx: "1",
                                            },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              fill: "#335EEA",
                                              x: "6",
                                              y: "15",
                                              width: "5",
                                              height: "2",
                                              rx: "1",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._m(5),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item dropdown" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: { to: { path: "/status" } },
                      },
                      [_vm._v("Status")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item dropdown" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: { to: { path: "/faq" } },
                      },
                      [_vm._v("FAQ")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item dropdown" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: { to: { path: "/pricing" } },
                      },
                      [_vm._v("Pricing")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item dropdown" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: { to: { path: "/review" } },
                      },
                      [_vm._v("Review")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.$root.context.id
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "navbar-btn btn btn-sm btn-primary lift ms-auto",
                      attrs: { to: { path: "/dashboard" } },
                    },
                    [_vm._v("Console")]
                  )
                : _c("ul", { staticClass: "navbar-nav ms-auto" }, [
                    _c(
                      "li",
                      { staticClass: "nav-item dropdown" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "navbar-btn btn btn-sm btn-primary lift ms-auto",
                            attrs: { to: { path: "/registration" } },
                          },
                          [_vm._v("Register")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "nav-item dropdown" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: { to: { path: "/login" } },
                          },
                          [_vm._v("Login")]
                        ),
                      ],
                      1
                    ),
                  ]),
            ],
            1
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("main", [_c("router-view")], 1),
    _vm._v(" "),
    _c("div", { staticClass: "position-relative" }, [
      _c(
        "div",
        {
          staticClass:
            "shape shape-bottom shape-fluid-x svg-shim text-gray-200",
        },
        [
          _c(
            "svg",
            {
              attrs: {
                viewBox: "0 0 2880 48",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z",
                  fill: "currentColor",
                },
              }),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "footer",
      { staticClass: "footer py-8 py-md-11 bg-gray-200" },
      [_c("Footer")],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarCollapse",
          "aria-controls": "navbarCollapse",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarCollapse",
          "aria-controls": "navbarCollapse",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [_c("i", { staticClass: "fe fe-x" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ms-4" }, [
      _c("h6", { staticClass: "fw-bold text-uppercase text-primary mb-0" }, [
        _vm._v("Documentation"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "fs-sm text-gray-700 mb-0" }, [
        _vm._v("Handbook with tutorials"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ms-4" }, [
      _c("h6", { staticClass: "fw-bold text-uppercase text-primary mb-0" }, [
        _vm._v("Swagger UI"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "fs-sm text-gray-700 mb-0" }, [
        _vm._v("Making requests online"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ms-4" }, [
      _c("h6", { staticClass: "fw-bold text-uppercase text-primary mb-0" }, [
        _vm._v("ReDoc"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "fs-sm text-gray-700 mb-0" }, [
        _vm._v("Documentation with examples"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ms-4" }, [
      _c("h6", { staticClass: "fw-bold text-uppercase text-primary mb-0" }, [
        _vm._v("Changelog"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "fs-sm text-gray-700 mb-0" }, [
        _vm._v(
          "\n                        Keep track of changes\n                      "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }