<template>
    <div class="container">
        <div class="row gx-4">
            <div :class="`col-12 col-md-${parseInt(12 / plans.length)}`" v-for="plan in plans">
                <div class="card shadow-lg mb-6 mb-md-0 h-100">
                    <div class="card-body px-4 h-100 d-flex flex-column justify-content-between">
                        <div class="info">
                            <div class="text-center mb-3">
                                <span class="badge rounded-pill bg-primary-soft">
                                    <span class="h6 text-uppercase">{{ plan.name }}</span>
                                </span>
                            </div>
                            <div class="d-flex justify-content-center">
                                <span class="h4 mb-0 mt-2">{{ plan.currencySymbol }}</span>
                                <span class="price display-4 mb-0" data-monthly="plan.price">{{ plan.price }}</span>
                            </div>
                            <p class="text-center text-muted mb-5 text-wrap">per request</p>
                            <p class="text-center text-muted mb-5 text-wrap" v-html="plan.description_html"></p>
                            <div class="d-flex" v-for="spec in plan.specifications">
                                <div class="badge badge-rounded-circle bg-success-soft mt-1 me-2"><i class="fe fe-check"></i></div>
                                <p v-html="spec"></p>
                            </div>
                        </div>
                        <router-link :to="{path: `/plan/choose/${plan.id}`}"
                                     class="btn w-100 btn-primary" role="button" :aria-disabled="true"
                                     :class="{'btn-primary-soft': !plan.is_popular, 'disabled': isDisabled(plan.id)}"
				     v-if="!isDisabled(plan.id)">
                            {{ button }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        button: {
            type: String,
            default: 'Buy'
        },
        disabledId: {
            type: Number
        },
    },
    data() {
        return {
            plans: []
        }
    },
    created() {
        this.get()
    },
    methods: {
        get() {
            this.$root.Plan.get().then(resp => {
                this.plans = resp.body
            })
        },
        isDisabled(id) {
            return id == this.disabledId
        },
    }
}
</script>
