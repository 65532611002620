var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "pt-8 pt-md-5 pb-8 pb-md-14" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "alert alert-primary", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n                            If you need to use Visa/MasterCard, please follow us on "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: `https://rapidapi.com/NikitusLLP/api/instagram-premium-api-2023`,
                    },
                  },
                  [_vm._v("RapidApi")]
                ),
                _vm._v("  and pay there using your preferred payment method. "),
                _c("br"),
                _vm._v(
                  " You'll find the same API there (it's our API hosted on a different platform).\n                 "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        !_vm.loaded
          ? _c("Spinner")
          : _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("h1", { staticClass: "mb-0 fw-bold" }, [
                  _vm._v("Top up your balance"),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mb-6 text-muted" }, [
                  _vm._v(
                    "On this page you can make a payment in a convenient way"
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "table-responsive" }, [
                  _c("h3", [_vm._v("Worldwide")]),
                  _vm._v(" "),
                  _c("table", { staticClass: "table table-striped" }, [
                    _c(
                      "tbody",
                      _vm._l(_vm.paySystems, function (system) {
                        return system.group == "ww"
                          ? _c("tr", [
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.system,
                                      expression: "form.system",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "paysystem",
                                    id: `p${system.id}`,
                                  },
                                  domProps: {
                                    value: system,
                                    checked: _vm._q(_vm.form.system, system),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "system",
                                        system
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-nowrap",
                                    attrs: { for: `p${system.id}` },
                                  },
                                  [_vm._v(_vm._s(system.description))]
                                ),
                                _vm._v(" "),
                                system.client_fee_percent < 0
                                  ? _c("div", { staticClass: "text-success" }, [
                                      _vm._v(
                                        "\n                                        We give " +
                                          _vm._s(-system.client_fee_percent) +
                                          "% when using this payment method\n                                    "
                                      ),
                                    ])
                                  : system.client_fee_percent > 0
                                  ? _c("div", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                                        Fee " +
                                          _vm._s(system.client_fee_percent) +
                                          "% when using this payment method\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "label",
                                  { attrs: { for: `p${system.id}` } },
                                  [_vm._v(_vm._s(system.currency__code))]
                                ),
                              ]),
                            ])
                          : _vm._e()
                      }),
                      0
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "table-responsive" }, [
                  _c("h3", [_vm._v("Russian Federation")]),
                  _vm._v(" "),
                  _c("table", { staticClass: "table table-striped" }, [
                    _c(
                      "tbody",
                      _vm._l(_vm.paySystems, function (system) {
                        return system.group == "ru"
                          ? _c("tr", [
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.system,
                                      expression: "form.system",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: "paysystem",
                                    id: `p${system.id}`,
                                  },
                                  domProps: {
                                    value: system,
                                    checked: _vm._q(_vm.form.system, system),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "system",
                                        system
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-nowrap",
                                    attrs: { for: `p${system.id}` },
                                  },
                                  [_vm._v(_vm._s(system.description))]
                                ),
                                _vm._v(" "),
                                system.client_fee_percent < 0
                                  ? _c("div", { staticClass: "text-success" }, [
                                      _vm._v(
                                        "\n                                        We give " +
                                          _vm._s(-system.client_fee_percent) +
                                          "% when using this payment method\n                                    "
                                      ),
                                    ])
                                  : system.client_fee_percent > 0
                                  ? _c("div", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                                        Fee " +
                                          _vm._s(system.client_fee_percent) +
                                          "% when using this payment method\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "label",
                                  { attrs: { for: `p${system.id}` } },
                                  [_vm._v(_vm._s(system.currency__code))]
                                ),
                              ]),
                            ])
                          : _vm._e()
                      }),
                      0
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "table-responsive" }, [
                  _c("h3", [_vm._v("Web3")]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-secondary" }, [
                    _vm._v(
                      "These addresses are tied exclusively to your account, you can deposit with any amount of USDT"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("table", { staticClass: "table table-striped" }, [
                    _c(
                      "tbody",
                      _vm._l(_vm.paySystems, function (system) {
                        return system.group == "cc"
                          ? _c("tr", { attrs: { init: _vm.initQR(system) } }, [
                              _c("td", { attrs: { colspan: "2" } }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-nowrap",
                                    attrs: { for: `p${system.id}` },
                                  },
                                  [_vm._v(_vm._s(system.description))]
                                ),
                                _vm._v(" "),
                                system.client_fee_percent < 0
                                  ? _c("div", { staticClass: "text-success" }, [
                                      _vm._v(
                                        "\n                                        We give " +
                                          _vm._s(-system.client_fee_percent) +
                                          "% when using this payment method\n                                    "
                                      ),
                                    ])
                                  : system.client_fee_percent > 0
                                  ? _c("div", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                                        Fee " +
                                          _vm._s(system.client_fee_percent) +
                                          "% when using this payment method\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("hr"),
                                _vm._v(" "),
                                _c("pre", [
                                  _vm._v(
                                    "                                        "
                                  ),
                                  _c("code", [_vm._v(_vm._s(system.address))]),
                                  _vm._v(
                                    "\n                                    "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("div", [
                                  _c("img", {
                                    attrs: {
                                      src: system.qr,
                                      alt: `QR for ${system.name}`,
                                    },
                                  }),
                                ]),
                              ]),
                            ])
                          : _vm._e()
                      }),
                      0
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.system.id,
                        expression: "form.system.id",
                      },
                    ],
                    staticClass: "mb-6",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.pay.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "amount" },
                          },
                          [_vm._v("Amount")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-3" }, [
                          _c(
                            "span",
                            {
                              staticClass: "input-group-text",
                              attrs: { id: "basic-addon1" },
                            },
                            [_vm._v(_vm._s(_vm.form.system.currency__symbol))]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.amount,
                                expression: "form.amount",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "number",
                              min: _vm.minAmount,
                              id: "amount",
                              placeholder: "Enter the amount",
                              "aria-describedby": "basic-addon1",
                            },
                            domProps: { value: _vm.form.amount },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "amount",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("br"),
                          _vm._v(" "),
                          _c("div", { attrs: { clear: "both" } }),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.errors.amount, function (error) {
                          return _c(
                            "small",
                            { staticClass: "form-text text-danger" },
                            [_vm._v(_vm._s(error))]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn w-100 btn-primary",
                        attrs: { type: "submit", disabled: _vm.disabled },
                      },
                      [
                        _vm._v(
                          "\n                        Pay\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }